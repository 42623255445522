/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { adminVariables } from '../store/adminEnviron'
import {
  infoLog,
  storeLog,
  storeLogDebug,
  debugLog,
  errorLog,
  warningLog
} from "../s48/logger"
import { listByParentId } from "../s48Data/AdsReactistObjectsByNameSpace_DAL"
import { listByNameSpace, listByNameSpaceObjects } from "../s48Data/listObjectsByNameSpace_DAL_V2"
import { listByParentAndNameSpace, listByParentAndOrder, listByParentAndName, listByParentAndNameandNameSpace } from '../s48Data/API_listByParent'
import { ConstructObject } from "../s48/ObjectInterface"

import { listByNameSpaceAndName } from "../s48Data/API_listByNameSpace"

export const populateUsers = createAsyncThunk(
  "adler/populateUsers",
  async (value) => {

    try {
      const response = await listByParentAndNameSpace(process.env.REACT_APP_S48_USERSFOLDERID, 'SECURITY.USERS.USER')
      //console.log('populateUsers', response)
      return {
        users: response.objects
      }
    } catch (error) {
      //console.log("adler/populateUsers", error)
    }
  }
)

export const listAllProceduresByRootId = createAsyncThunk(
  "adler/listAllProceduresByRootId",
  async (value) => {

    try {
      const response = await listByParentAndName(value)
      //console.log('listAllProceduresByRootId', response)
      return {
        data: response.objects
      }
    } catch (error) {
      console.error("adler/listAllProceduresByRootId", error)
    }
  }
)

export const listAllProceduresByRootIdandNameSpace = createAsyncThunk(
  "adler/listAllProceduresByRootIdandNameSpace",
  async (value, nameSpaceIn) => {

    try {
      const response = await listByParentAndNameandNameSpace(value, nameSpaceIn)
      console.log('listAllProceduresByRootIdandNameSpace', response)
      return {
        data: response.objects
      }
    } catch (error) {
      console.error("adler/listAllProceduresByRootIdandNameSpace", error)
    }
  }
)
export const listObIdWithNameSpace = createAsyncThunk(
  "adler/listAllProceduresByNameSpace",
  async (nameSpaceIn) => {

    try {
      const response = await listByNameSpace(nameSpaceIn)

      const parentId = response.objects
      console.log('listAllProceduresByNameSpace................', response)
      console.log(response[0].object.obId)
      return {
       
        data: response[0].object.obId
      }
    } catch (error) {
      console.error("adler/listAllProceduresByNameSpace", error)
    }
   
  }
)

export const listAllProceduresByParent = createAsyncThunk(
  "adler/listAllProceduresByParent",
  async (value) => {

    try {
      const response = await listByParentAndOrder(value)

      return {
        steps: response.objects
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const listAllQuestionsByParent = createAsyncThunk(
  "adler/listAllQuestionsByParent",
  async (value) => {

    try {
      const response = await listByParentAndOrder(value)

      return {
        questions: response.objects
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const listRegisteredProceduresActive = createAsyncThunk(
  "adler/listRegisteredProceduresActive",
  async (value) => {
    //will return a
    //Look for ADLER.REGISTEREDOP||PLANNED", /ACTIVE ONLY
    //QUERY TWICE FOR SILOB and C ||PLANNED
    //QUERY TWICE FOR SILOB and C ||ACTIVE
    //ALL ACTIVE / PLANNED REGISTERED FOR REP
    return {
      data: value
    }
  }
)

export const listRegisteredProceduresPending = createAsyncThunk(
  "adler/listRegisteredProceduresPending",
  async (value) => {
    //will return a
    //Look for ADLER.REGISTEREDOP||PLANNED", /ACTIVE ONLY
    //QUERY TWICE FOR SILOB and C ||PLANNED
    //QUERY TWICE FOR SILOB and C ||ACTIVE
    //ALL ACTIVE / PLANNED REGISTERED FOR REP
    return {
      data: value
    }
  }
)

export const listRegisteredProceduresComplete = createAsyncThunk(
  "adler/listRegisteredProceduresComplete",
  async (value) => {
    //QUERY TWICE FOR SILOB and C ||COMPLETE
    return {
      data: value
    }
  }
)

export const addRegisteredProcedure = createAsyncThunk(
  "adler/addRegisteredProcedure",
  async (value) => {
    //whicgh procedure
    //who and when
    //hospital
    return {
      data: value
    }
  }
)

export const setProcedureToActive = createAsyncThunk(
  "adler/setProcedureToActive",
  async (value) => {
    //which procedure
    //who and when
    //hospital
    //DUPLICATE STEPS
    return {
      data: value
    }
  }
)

export const setProcedureToComplete = createAsyncThunk(
  "adler/setprocedrueToActive",
  async (value) => {
    //whicgh procedure
    //who and when
    //hospital
    return {
      data: value
    }
  }
)

export const setSurgeonStep = createAsyncThunk(
  "adler/setSurgeonStep",
  async (value) => {
    //whicgh procedure
    //who and when
    //hospital
    return {
      data: value
    }
  }
)

export const setScrubStep = createAsyncThunk(
  "adler/setScrubStep",
  async (value) => {
    //which procedure
    //who and when
    //hospital
    return {
      data: value
    }
  }
)

export const deleteRegisteredProcedures = createAsyncThunk(
  "adler/addRegisteredProcedures",
  async (value) => {
    //whicgh procedure
    //who and when
    //hospital
    return {
      data: value
    }
  }
)


//export const moveToStep = createAsyncThunk(

export const appSlice = createSlice({
  name: "adler",
  initialState: {
    initializingContent: false,
    intilized: false,
    initializeWorkstream: false,
    userData: {},
    sekcode: "",
    loggedIn: null,
    signInFails: 0,
    forgotPwCount: 0,
    loadText: "LOADING",
    mockData: false,
    currentProcedure: null,
    evalQs: [],
    currentStep: null,
    procedureSteps: [],
    currentProjectId:"",
    procedures: [],
    surgUsers: [],
    scrubUsers: [],
    repUsers: [],
    surgUserSelectOptions: [],
    scrubUserSelectOptions: [],
    repUserSelectOptions: [],
    hospitals: [{ value: 'QAH', label: 'Portsmouth Hospitals University NHS Trust' }, { value: 'SGH', label: 'Southampton University Hospital' }, { value: 'Winchester', label: 'Hampshire Hospitals NHS Foundation Trust' }]

  },
  reducers: {
    setMocking: (state, action) => {
      state.mockData = action.payload //USE FOR DEV ONLY
    },
    clearState: (state) => {
      //state = this.initialState
    },
    incrementSignInFails: (state) => {
      state.signInFails = state.signInFails + 1
    },
    resetSignInFails: (state) => {
      state.signInFails = 0
    },
    incrementForgotPwCount: (state) => {
      state.forgotPwCount = state.forgotPwCount + 1
    },
    resetForgotPwCount: (state) => {
      state.forgotPwCount = 0
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
    setUserDataState: (state, action) => {
      //storeLogDebug("Setting user State:", action.payload)
      if (action.payload === undefined) {
        warningLog("STORE: setUserDataState action.payload is undefined")
        state.userData = null
      } else {
        storeLogDebug("UserData state set...:")
        state.userData = action.payload
        state.sekcode = action.payload.ability
      }
    },
    setCurrentProcedure: (state, action) => {

      if (!action.payload) {
        warningLog("STORE: setCurrentProcedure action.payload is undefined")
        state.currentProcedure = null
      } else {
        state.currentProcedure = action.payload
      }
    },
    setCurrentStep: (state, action) => {

      if (!action.payload) {
        warningLog("STORE: setCurrentStep action.payload is undefined")
        state.currentStep = null
      } else {
        state.currentStep = action.payload
      }
    },
    setCurrentProjectId: (state, action) => {

      if (!action.payload) {
        warningLog("STORE: setCurrentProjectId action.payload is undefined")
        state.projectId = null
      } else {
        state.projectId = action.payload
      }
    },
    updateProcedure: (state, action) => {
      if (action.payload === undefined) {
        warningLog("STORE: updateProcedure action.payload is undefined")

      } else {
        //storeLogDebug("updating procedure array ...:")
        // state.userData = action.payload
        // state.sekcode = action.payload.ability
        const tempArray = [...state.procedures]
        const newObj = action.payload
        const index = tempArray.findIndex((obj) => obj.obId === newObj.obId)
        if (index !== -1) {
          state.procedures = [...tempArray.slice(0, index), newObj, ...tempArray.slice(index + 1)]
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(populateUsers.fulfilled, (state, action) => {
        try {
          //storeLogDebug("populateUsers.fulfilled", action.payload)
          const surgArray = []
          const scrubArray = []
          const repArray = []
          const surgArraySelectOptions = []
          const scrubArraySelectOptions = []
          const repArraySelectOptions = []

          if (action.payload.users.length) {
            action.payload.users.forEach(element => {
              const obj = ConstructObject(element)
              switch (obj?.properties?.usertype?.value ?? '') {
                case 'surgeon':
                  surgArraySelectOptions.push({ value: obj?.objName ?? 'username empty', label: `${obj?.properties?.firstname?.value ?? 'firstname empty'}  ${obj?.properties?.surname?.value ?? 'surname empty'}` })
                  surgArray.push(obj)
                  break
                case 'scrub':
                  scrubArraySelectOptions.push({ value: obj?.objName ?? 'username empty', label: `${obj?.properties?.firstname?.value ?? 'firstname empty'}  ${obj?.properties?.surname?.value ?? 'surname empty'}` })
                  scrubArray.push(obj)
                  break
                case 'rep':
                  repArraySelectOptions.push({ value: obj?.objName ?? 'username empty', label: `${obj?.properties?.firstname?.value ?? 'firstname empty'}  ${obj?.properties?.surname?.value ?? 'surname empty'}` })
                  repArray.push(obj)
                  break

                default:
                  break
              }
            })
            state.surgUsers = surgArray
            state.scrubUsers = scrubArray
            state.repUsers = repArray
            state.surgUserSelectOptions = surgArraySelectOptions
            state.scrubUserSelectOptions = scrubArraySelectOptions
            state.repUserSelectOptions = repArraySelectOptions
          }

        } catch (error) {
          errorLog("populateUsers.fulfilled", error.message)
        }
      })
      .addCase(listAllProceduresByRootId.fulfilled, (state, action) => {
        if (action.payload.data) {
          try {
            //storeLogDebug("listAllProceduresByRootId.fulfilled", action.payload)
            const objArray = []
            if (action.payload.data.length) {
              action.payload.data.forEach(element => {
                objArray.push(ConstructObject(element))
              })
            }
            //console.log('objArray', objArray)
            objArray.sort((a, b) => a.order - b.order)
            state.procedures = objArray
          } catch (error) {
            errorLog("listAllProceduresByRootId.fulfilled", error.message)
          }
        }
      })
      .addCase(listAllProceduresByRootIdandNameSpace.fulfilled, (state, action) => {
        if (action.payload.data) {

          console.log('action payload data is... ', action.payload.data)
          try {
            //storeLogDebug("listAllProceduresByRootId.fulfilled", action.payload)
            const objArray = []
            if (action.payload.data.length) {
              action.payload.data.forEach(element => {
                if (element.object.nameSpace === 'ADLER.PROCEDURE') {
                objArray.push(ConstructObject(element))
                }
              })
            }
            //console.log('objArray', objArray)
            objArray.sort((a, b) => a.order - b.order)
            state.procedures = objArray
          } catch (error) {
            errorLog("listAllProceduresByRootId.fulfilled", error.message)
          }
        }
      })
      .addCase(listObIdWithNameSpace.fulfilled, (state, action) => {
        if (action.payload.data) {

          console.log('action payload data is... ', action.payload.data)
          try {
            //storeLogDebug("listAllProceduresByRootId.fulfilled", action.payload)
            // const objArray = []
            // if (action.payload.data.length) {
            //   action.payload.data.forEach(element => {
            //     // if (element.object.nameSpace === 'ADLER.PROCEDURE') {
            //     objArray.push(ConstructObject(element))
            //     // }
            //   })
            // }
            // console.log('objArray', objArray)
            // objArray.sort((a, b) => a.order - b.order)
            state.currentProjectId = action.payload.data
          } catch (error) {
            errorLog("listAllProceduresByNameSpace.fulfilled", error.message)
          }
        }
      })
      .addCase(listAllProceduresByParent.fulfilled, (state, action) => {
        try {
          //storeLogDebug("listAllProceduresByParent.fulfilled", action.payload)
          const objArray = []
          if (action.payload.steps.length) {
            action.payload.steps.forEach(element => {
              //console.log('element...', element.object.nameSpace)
              if (element.object.nameSpace === 'ADLER.PROCEDURESTEP') {
                objArray.push(ConstructObject(element))
              }
            })
          }
          //console.log('objArray', objArray)
          objArray.sort((a, b) => a.order - b.order)
          state.procedureSteps = objArray
        } catch (error) {
          errorLog("listAllProceduresByParent.fulfilled", error.message)
        }
      })
      .addCase(listAllQuestionsByParent.fulfilled, (state, action) => {
        try {
          //storeLogDebug("listAllProceduresByParent.fulfilled", action.payload)
          const objArray = []
          if (action.payload.questions.length) {
            action.payload.questions.forEach(element => {
              //console.log('element...', element.object.nameSpace)
              if (element.object.nameSpace === 'ADLER.FEEDBACK.PREEVALQUESTION') {
                objArray.push(ConstructObject(element))
              }
            })
          }
          //console.log('objArray', objArray)
          objArray.sort((a, b) => a.order - b.order)
          state.evalQs = objArray
        } catch (error) {
          errorLog("listAllQuestionsByParent.fulfilled", error.message)
        }
      })
      .addCase(listRegisteredProceduresActive.fulfilled, (state, action) => {
        try {
          storeLogDebug("listRegisteredProceduresActive.fulfilled")
        } catch (error) {
          errorLog("listRegisteredProceduresActive.fulfilled", error.message)
        }
      })
      .addCase(listRegisteredProceduresPending.fulfilled, (state, action) => {
        try {
          //storeLogDebug("listRegisteredProceduresPending.fulfilled")
        } catch (error) {
          errorLog("listRegisteredProceduresPending.fulfilled", error.message)
        }
      })
      .addCase(listRegisteredProceduresComplete.fulfilled, (state, action) => {
        try {
          storeLogDebug("listRegisteredProceduresComplete.fulfilled")
        } catch (error) {
          errorLog("listRegisteredProceduresComplete.fulfilled", error.message)
        }
      })
      .addCase(addRegisteredProcedure.fulfilled, (state, action) => {
        try {
          storeLogDebug("addRegisteredProcedure.fulfilled")
        } catch (error) {
          errorLog("addRegisteredProcedure.fulfilled", error.message)
        }
      })
      .addCase(setProcedureToActive.fulfilled, (state, action) => {
        try {
          storeLogDebug("setProcedureToActive.fulfilled")
        } catch (error) {
          errorLog("setProcedureToActive.fulfilled", error.message)
        }
      })
      .addCase(setProcedureToComplete.fulfilled, (state, action) => {
        try {
          storeLogDebug("setProcedureToComplete.fulfilled")
        } catch (error) {
          errorLog("setProcedureToComplete.fulfilled", error.message)
        }
      })
      .addCase(setSurgeonStep.fulfilled, (state, action) => {
        try {
          storeLogDebug("setSurgeonStep.fulfilled")
        } catch (error) {
          errorLog("setSurgeonStep.fulfilled", error.message)
        }
      })
      .addCase(setScrubStep.fulfilled, (state, action) => {
        try {
          storeLogDebug("setScrubStep.fulfilled")
        } catch (error) {
          errorLog("setScrubStep.fulfilled", error.message)
        }
      })
  }
})

export const {
  resetState,
  incrementSignInFails,
  incrementForgotPwCount,
  resetSignInFails,
  resetForgotPwCount,
  setLoggedIn,
  setUserDataState,
  setMocking,
  updateProcedure,
  setCurrentProcedure,
  setCurrentProjectId,
  setCurrentStep
} = appSlice.actions

export default appSlice.reducer
