import { API, graphqlOperation } from 'aws-amplify'
import { ConstructObject } from '../s48/ObjectInterface'
import { getUserData } from '../../src/s48/IdentityManager'
import { warningLog } from '../s48/logger'

const listByNameSpaceQuery = /* GraphQL */ `
query listByNameSpace(
  $nameSpace: String!,
  $sectok: String) {
  listByNameSpace(
    nameSpace: $nameSpace,
    sectok: $sectok) {
    objects {
      object {
        createdAt
        creator
        editor
        environment
        id
        nameSpace
        obId
        objName
        order
        parentId
        propertiesData
        prvid
        status
        updatedAt
        assignStart
        assignedById
        assignedToGroupId
        assignedToGroupName
        assignedToId
        assignedToName
        assignNote
        assignUrgency
        assignDeadline
      }
    }
    response {
      count
      detail
      hash
      message
      statusCode
    }
  }
}
`

export const listByNameSpace = async (nameSpaceIn) => {
  try {
    console.log('listByNameSpace evoked')
    const userData = await getUserData()
    console.log('userdata.....', userData)
    
    const sectokData = userData?.ability //todo add defensive if userData.ability i sundefined...
    if (sectokData === undefined || sectokData === "" || sectokData === null) {
      warningLog("userData does not have sectok - query cannot run")
      return null
    }
    const objectData = await API.graphql(graphqlOperation(listByNameSpaceQuery,
      {
        nameSpace: nameSpaceIn,
        sectok: sectokData
      }))
    const objects = objectData.data.listByNameSpace.objects
    console.log('objects:... ', objects)
    return objects
  } catch (err) {
    console.log("ERROR listByNameSpace ######################################")
    console.log(`input - nameSpaceIn${nameSpaceIn}`)
    console.log(err)
    console.log("ERROR END ############################################################")
  }
}

export const listByNameSpaceObjects = async (nameSpaceIn) => {
  console.log('listByNameSpace evoked')
  const userData = await getUserData()
  console.log('userdata.....', userData)
  const sectokData = userData?.ability //todo add defensive if userData.ability i sundefined...
    if (sectokData === undefined || sectokData === "" || sectokData === null) {
      warningLog("userData does not have sectok - query cannot run")
      return null
    }
  try {
    const objectData = await API.graphql(graphqlOperation(listByNameSpaceQuery,
      {
        nameSpace: nameSpaceIn,
        sectok: sectokData
      }))
    const objects = objectData.data.listByNameSpace.objects
    const constructedObjectsList = []
    objects.forEach((obj) => {
      constructedObjectsList.push(
        { object: ConstructObject(obj) }
      )
    })
    return constructedObjectsList
  } catch (err) {
    console.log("ERROR listByNameSpaceObjects ######################################")
    console.log(`input - nameSpaceIn${nameSpaceIn}`)
    console.log(err)
    console.log("ERROR END ############################################################")
  }
}
