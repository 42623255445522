import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Row, Container, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { brandColours } from '../styles/BrandColours'
import { AbilityManager } from '../s48/AbilityManager'
import LoadingSpinner from "../extracomponents/LoadingSpinner"
import { adminVariables } from '../store/adminEnviron'


const LoginScreen = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPass, setShowPass] = useState('password')
  const [showText, setShowText] = useState('show')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  function showPassword() {
    setShowPass('text')
    setShowText('hide')
  }

  function hidePassword() {
    setShowPass('password')
    setShowText('show')
  }

  const showtext = () => {
    // console.log('presseddddd')
    showPass === 'password' ? showPassword() : hidePassword()
  }

  const handleLogin = async (event) => {
    setIsLoading(true)
    event.preventDefault()
    try {
      const loginResult = await Auth.signIn(username, password)
      const abmanager = new AbilityManager()
      const abs = await abmanager.GetAbilitesFromAPI(adminVariables.apiid) //should at minimum return the API key as an xiid ability
      const absEncypted = abs.encab
      const absPlain = abs.abils

      const absArray = []
      const userData = {
        id: loginResult.attributes.sub,
        fullName: username,
        username,

        userOrgId: "",
        avatar: "",
        //groups: userGroups,
        email: loginResult.attributes.email,
        role: "user",
        ability: absEncypted,
        abs: absArray
      }
      const projectArray = []
      console.log('userdata is ..', abs.abils)
      JSON.parse(abs.abils).forEach(element => {
        console.log('ability is ..', element.project)
        if (element.project !== undefined) {
       projectArray.push(element.project)
        }
      })
      console.log('projectArray is ..', projectArray)
      localStorage.setItem('projects', JSON.stringify(projectArray))
      localStorage.setItem('userData', JSON.stringify(userData))

      navigate('/mainmenu')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Container className='container'>
        <div className="imageHolder">
          <img src={require("../assets/amodisc_logo_white.png")} alt="" width="50%" />
          <h1 style={{ textAlign: "center" }}>Remote Support</h1>
        </div>
        <Form className='form' onSubmit={handleLogin}>
          <Input className='input' type="text" placeholder='username' name="username" id="username" value={username} onChange={(event) => setUsername(event.target.value)} />
          <Input className='input' type={showPass} placeholder='password' name="password" id="password" value={password} onChange={(event) => setPassword(event.target.value)} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', color: brandColours.adlerBlue }} onMouseDown={showtext}>{showText}</div>
          <Button className='blueButton' type="submit" color="primary">Login</Button>
          {isLoading ? <LoadingSpinner /> : <></>}
        </Form>
      </Container>
    </>
  )
}

export default LoginScreen
