//import useFetch from "../useFetch";
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { listRegisteredProceduresPending, listObIdWithNameSpace, setCurrentProjectId} from "../store"
import Navbar from "./Navbar"
import { useEffect, useState } from "react"

const ProjectScreen = () => {
    //console.log("Im alive")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [items, setItems] = useState(JSON.parse(localStorage.getItem('projects')))
    const getProjId = async (project) => {
     dispatch(listObIdWithNameSpace(`AMOSUPPORT.ROOT||${project}`))
      
    }
useEffect(() => {
    console.log('projects: ', items)
   getProjId()
}) 


const handleClick = (item) => {
  getProjId(item)
    navigate('/procedurescreen/')
}
    return (
        <div >
            <Navbar pageTitle="Choose Project" />
            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', marginTop: '10vh' }}>
                {/* <div className="list-preview" style={{ color: 'lightgrey' }} onMouseDown={() => console.log('hit')} >Generic Procedures</div>
                <div className="list-preview" style={{ color: 'black' }} onMouseDown={() => navigate('/procedurescreen/')}>Custom Procedures</div>
                */}
                <div style={{ padding: "20px" }}> 
      
      <ul>
        {items.map((item) => (
          <li key={item.id} style={{ margin: "10px 0" }}>
            
            <h3 onClick={() => handleClick(item)}>{item}</h3>
            
          </li>
        ))}
      </ul>
    </div>
            </div>
        </div>
    )
}

export default ProjectScreen