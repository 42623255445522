//import useFetch from "../useFetch";
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { listRegisteredProceduresPending, listAllQuestionsByParent, setQuestions } from "../store"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"

const MainMenu = () => {
    //console.log("Im alive")
    const { opId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //const data = dispatch(listRegisteredProceduresPending())
    useEffect(() => {
        dispatch(listAllQuestionsByParent(`GCOGN8Z09M_LIVE`))
        // dispatch(populateUsers())
       // objArray = store.evalQs

        
    }, [])
    return (
        <div className="container">
            <Navbar pageTitle="Choose" />
           
            <div >
                <div>
                    <div className="blueButton" onMouseDown={() => navigate('/projectscreen')} >Project Support</div>
                </div>
                <div>
                    <div className="whiteButton" onMouseDown={() =>  navigate('/videoviewer')} >Hololens Tutorial</div>
                </div>
               
                <div>
                    <div className="whiteButton" onMouseDown={() =>  navigate('/evalquestion')} >Post-op Feedback</div>
                </div>
                
            </div>
        </div>
    )
}

export default MainMenu